export const SET_STATE = (state, { key, value }) => {
  state[key] = value;
};

export const TOGGLE_DARKMODE = (state) => {
  state.darkmode = !state.darkmode;
};

export const INITIALIZE = (state) => {
  state.isInitialized = true;
  state.darkmode = false;
};
