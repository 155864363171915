import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

export const getUsers = async ({ state, commit, rootState }) => {
  const db = firebase.firestore();

  commit('SET_STATE', { key: 'isLoading', value: true });
  let query;
  if (rootState.auth.claims.superAdmin || rootState.auth.claims.eyeStaffAdmin) {
    query = db.collection('users').where('role', 'in', ['superAdmin', 'eyeStaffAdmin', 'eyeStaff', 'orgAdmin', 'orgUser']);
  } else if (rootState.auth.claims.eyeStaff) {
    query = db.collection('users').where('role', 'in', ['orgAdmin', 'orgUser', 'eyeStaff']);
  } else {
    const snapshotUser = await db.doc(`users/${rootState.auth.providerLoginInfos.uid}`).get();
    const userData = snapshotUser.data();
    query = db.collection('users').where('role', 'in', ['orgAdmin', 'orgUser'])
      .where('organization', '==', userData.organization);
  }
  query.onSnapshot((snapshot) => {
    const users = [...state.users];

    const changes = snapshot.docChanges();
    changes.forEach((c) => {
      if (c.type === 'added') {
        const organization = c.doc.data().organization.id;

        users.push({ ...c.doc.data(), id: c.doc.id, organization });
      } else if (c.type === 'modified') {
        const userIndex = users.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          const organization = c.doc.data().organization.id;

          users[userIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
            organization,
          };
        }
      } else if (c.type === 'removed') {
        const userIndex = users.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          users.splice(userIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'users', value: users });
    commit('SET_STATE', { key: 'isLoading', value: false });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const submitUser = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });
  try {
    const createUser = firebase.functions().httpsCallable('createUser');
    const result = await createUser({
      ...payload,
    });
    if (result.data.isExists) {
      commit('SET_STATE', { key: 'error', value: 'Email address already exists.' });
    } else {
      commit('SET_STATE', { key: 'error', value: null });
    }
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const deleteUser = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });

  try {
    const deleteEyeUser = firebase.functions().httpsCallable('deleteUser');
    await deleteEyeUser({
      ...payload,
    });
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const getOwnerList = async ({ state, commit, rootState }) => {
  const db = firebase.firestore();
  const snapshotUser = await db.collection('users').doc(rootState.auth.providerLoginInfos.uid).get();
  const userData = snapshotUser.data();
  let query;
  if (rootState.auth.claims.orgAdmin) {
    const curreUserOrgRef = await db.collection('organizations').doc(userData.organization.id);
    query = db.collection('users').where('organization', '==', curreUserOrgRef);
  } else {
    query = db.collection('users').where(firebase.firestore.FieldPath.documentId(), '==', rootState.auth.providerLoginInfos.uid);
  }
  query.onSnapshot((snapshot) => {
    const ownerList = [...state.ownerList];
    const changes = snapshot.docChanges();
    changes.forEach((c) => {
      if (c.type === 'added') {
        const organization = c.doc.data().organization.id;

        ownerList.push({ ...c.doc.data(), id: c.doc.id, organization });
      } else if (c.type === 'modified') {
        const userIndex = ownerList.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          const organization = c.doc.data().organization.id;

          ownerList[userIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
            organization,
          };
        }
      } else if (c.type === 'removed') {
        const userIndex = ownerList.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          ownerList.splice(userIndex, 1);
        }
      }
    });
    commit('SET_STATE', { key: 'ownerList', value: ownerList });
    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};
