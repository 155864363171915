import firebase from 'firebase/app';
import 'firebase/auth';

export async function request({ state, commit }) {
  if (state.requested) return;
  commit('SET_STATE', { key: 'requested', value: true });

  firebase.auth().onAuthStateChanged(async (response) => {
    let claimResp;
    if (response) {
      claimResp = await firebase.auth().currentUser.getIdTokenResult();
    }
    commit('SET_ROLE', claimResp?.claims);
    commit('SET_STATE', { key: 'resolved', value: true });
    commit('SET_STATE', { key: 'username', value: response?.email });
    commit('SET_STATE', { key: 'claims', value: claimResp?.claims });
    commit('SET_LOGIN_PROVIDER_INFOS', response);
  });
}

export function setLoginProviderInfos({ commit }, object) {
  commit('SET_LOGIN_PROVIDER_INFOS', object);
}
