<template>
  <v-app-bar elevation="1" outlined app class="pr-3" height="75">
    <v-toolbar-title class="ml-0 pl-2 toolbar-title">
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon />
      </span>
      <div class="d-flex align-center mr-6">
        <v-row v-if="!isLoading">
          <!-- EYE LOGO -->
          <v-col>
            <span class="hidden-xs-only">
              <v-img src="../../assets/eye_staff_logo.png"
                max-height="60"
                max-width="150"
                contain
                :style="{ 'background-color': isDarkmodeEnabled ? 'white' : '' }"/>
            </span>
          </v-col>
        </v-row>
      </div>
    </v-toolbar-title>
    <v-toolbar-items>
      <v-btn text class="toolbar-nav-btn" v-for="item in links" :key="item.text" :to="item.to">
        <v-icon left dark>{{ item.icon }}</v-icon>
        {{ item.text }}
      </v-btn>
    </v-toolbar-items>
    <v-spacer />
    <v-toolbar-items>
      <div class="d-flex align-center mr-12">
        <span>{{ username }}</span>
      </div>
    </v-toolbar-items>

    <v-menu left bottom :offset-y="true">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>{{ menuIcon }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="item in listItems" :key="item.id" @click="onButtonClick(item.id)">
          <v-list-item-icon>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  mdiLogoutVariant,
  mdiMenu,
  mdiAccountCogOutline,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiFileDocument,
  mdiOpenInNew,
} from '@mdi/js';

export default {
  name: 'NavBar',
  data: () => ({
    menuIcon: mdiMenu,
    ressourcesIcon: mdiFileDocument,
    externalLinkIcon: mdiOpenInNew,
    links: [
      { text: 'Admin', to: '/', icon: mdiAccountCogOutline },
    ],
  }),
  methods: {
    onButtonClick(id) {
      if (id === 'darkmode') {
        this.$store.commit('userPreferences/TOGGLE_DARKMODE');
        this.$vuetify.theme.dark = this.isDarkmodeEnabled;
      } else if (id === 'logout') {
        firebase
          .auth()
          .signOut()
          .then(
            () => {
              document.location = '/';
            },
            () => {
              // console.error(err);
            },
          );
      }
    },
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      isAutoPlayEnabled: (state) => state.userPreferences.autoPlay,
      isDarkmodeEnabled: (state) => state.userPreferences.darkmode,
      isLoading: (state) => state.organizations.isLoading,
    }),
    listItems() {
      return [
        {
          icon: this.isDarkmodeEnabled ? mdiWeatherNight : mdiWeatherSunny,
          label: `${this.isDarkmodeEnabled ? 'Désactiver' : 'Activer'} le mode nuit`,
          color: this.isDarkmodeEnabled ? 'black' : 'yellow',
          id: 'darkmode',
        },
        {
          icon: mdiLogoutVariant,
          label: 'Logout',
          color: 'error',
          id: 'logout',
        },
      ];
    },
  },
  mounted() {
    if (!this.$store.state.organizations.isInitialized && !this.$store.state.auth.claims.superAdmin) {
      this.$store.dispatch('organizations/getCurrentUserOrg');
    }
  },
};
</script>
