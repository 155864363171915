import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import store from '@/store/auth/index.js';

Vue.use(VueRouter);

const routeGuard = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.role === 'orgUser') {
      firebase.auth().signOut();
      next('/login');
    } else {
      next();
    }
  }
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/',
    beforeEnter: routeGuard,
    component: () => import('@/views/Admin/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'users',
        name: 'User-Settings',
        component: () => import('@/views/Admin/Users.vue'),
      },
      {
        path: 'organisations',
        name: 'Organisations-Settings',
        component: () => import('@/views/Admin/Organisations.vue'),
      },
      {
        path: 'devices',
        name: 'Devices-Settings',
        component: () => import('@/views/Admin/Devices.vue'),
      },
      { path: '', redirect: 'users' },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
