// import Vue from 'vue';

export function SET_STATE(state, { key, value }) {
  // Vue.set(state, key, value);
  state[key] = value;
}

export function SET_LOGIN_PROVIDER_INFOS(state, infos) {
  // Vue.set(state, 'providerLoginInfos', infos);
  state.providerLoginInfos = infos;
}

export function SET_ROLE(state, claims) {
  try {
    const keys = ['superAdmin', 'eyeStaff', 'orgAdmin', 'eyeStaffAdmin'];
    const role = keys.find((key) => claims[key] && key);
    state.role = role || 'orgUser';
  } catch (error) {
    state.role = '';
  }
}
