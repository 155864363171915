export const SET_STATE = (state, { key, value }) => {
  state[key] = value;
};

export const INITIALIZE = (state) => {
  state.isInitialized = true;
};

export const RESET_STATE = (state) => {
  state.organizations = [];
  state.isInitialized = false;
  state.error = null;
  state.isLoading = false;
  state.userOrganization = '';
};
