import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import devices from './devices';
import organizations from './organizations';
import users from './users';
import userPreferences from './userPreferences';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    devices,
    organizations,
    users,
    userPreferences,
  },

  // for dev mode only
  strict: process.env.DEV,
});
