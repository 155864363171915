import firebase from 'firebase/app';
import 'firebase/firestore';

export const getDevices = ({ state, commit, rootState }) => {
  const db = firebase.firestore();
  commit('SET_STATE', { key: 'isLoading', value: true });
  db.collection('devices').where('isDefault', '==', false).onSnapshot(async (snapshot) => {
    const devices = [...state.devices];
    const changes = snapshot.docChanges();
    let latitude = '';
    let longitude = '';
    const snapshotUser = await db.collection('users').doc(rootState.auth.providerLoginInfos.uid).get();
    const userData = snapshotUser.data();
    let orgUsers;
    if (rootState.auth.claims.orgAdmin) {
      const curreUserOrgRef = db.collection('organizations').doc(userData.organization.id);
      const usersInOrg = await db.collection('users').where('organization', '==', curreUserOrgRef).get();
      orgUsers = usersInOrg.docs.map((x) => x.id);
    }
    changes.forEach((c) => {
      if (c.doc.data().position) {
        latitude = c.doc.data().position.latitude;
        longitude = c.doc.data().position.longitude;
      }
      if (c.type === 'added') {
        const owner = c.doc.data().owner.id;
        if (rootState.auth.claims.orgAdmin && orgUsers.includes(owner)) {
          devices.push({
            ...c.doc.data(),
            id: c.doc.id,
            latitude,
            longitude,
            owner,
          });
        } else if ((rootState.auth.claims.orgUser || rootState.auth.claims.orgAdmin)
          && rootState.auth.providerLoginInfos.uid === owner) {
          devices.push({
            ...c.doc.data(),
            id: c.doc.id,
            latitude,
            longitude,
            owner,
          });
        }
      } else if (c.type === 'modified') {
        const deviceIndex = devices.findIndex((s) => s.id === c.doc.id);
        const owner = c.doc.data().owner.id;

        if (deviceIndex !== -1) {
          devices[deviceIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
            latitude,
            longitude,
            owner,
          };
        }
      } else if (c.type === 'removed') {
        const deviceIndex = devices.findIndex((s) => s.id === c.doc.id);

        if (deviceIndex !== -1) {
          devices.splice(deviceIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'devices', value: devices });
    commit('SET_STATE', { key: 'isLoading', value: false });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const submitDevice = async ({ commit }, { payload }) => {
  const db = firebase.firestore();
  commit('SET_STATE', { key: 'isLoading', value: true });
  try {
    const { latitude, longitude, ...newPayload } = payload;
    let position = '';
    if (payload.latitude && payload.longitude) {
      position = new firebase.firestore.GeoPoint(payload.latitude, payload.longitude);
    }
    if (payload.id) {
      await db
        .collection('devices')
        .doc(payload.id)
        .update({
          ...newPayload,
          position,
          owner: db.doc(`users/${payload.owner}`),
        });
    } else {
      await db.collection('devices').add({
        ...newPayload,
        position,
        owner: db.doc(`users/${payload.owner}`),
      });
    }
    commit('SET_STATE', { key: 'error', value: null });
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const deleteDevice = async ({ commit }, { payload }) => {
  const db = firebase.firestore();
  commit('SET_STATE', { key: 'isLoading', value: true });

  try {
    await db.collection('devices').doc(payload.id).delete();
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const getDevicesForCount = ({ state, commit }) => {
  const db = firebase.firestore();
  db.collection('devices').onSnapshot((snapshot) => {
    const allDevices = [...state.allDevices];
    const changes = snapshot.docChanges();
    changes.forEach((c) => {
      if (c.type === 'added') {
        const owner = c.doc.data().owner.id;
        allDevices.push({
          owner,
          id: c.doc.id,
        });
      } else if (c.type === 'modified') {
        const deviceIndex = allDevices.findIndex((s) => s.id === c.doc.id);
        const owner = c.doc.data().owner.id;
        if (deviceIndex !== -1) {
          allDevices[deviceIndex] = {
            owner,
            id: c.doc.id,
          };
        }
      } else if (c.type === 'removed') {
        const deviceIndex = allDevices.findIndex((s) => s.id === c.doc.id);
        if (deviceIndex !== -1) {
          allDevices.splice(deviceIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'allDevices', value: allDevices });
    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};
