import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

export const getOrganizations = ({ state, commit }) => {
  const db = firebase.firestore();

  commit('SET_STATE', { key: 'isLoading', value: true });

  db.collection('organizations').onSnapshot((snapshot) => {
    const organizations = [...state.organizations];
    const changes = snapshot.docChanges();
    changes.forEach(async (c) => {
      if (c.type === 'added') {
        const organization = { ...c.doc.data(), id: c.doc.id };
        try {
          const orgLogoLink = decodeURIComponent(organization.orgLogoLink);
          organization.orgLogoLink = await firebase.storage().refFromURL(orgLogoLink).getDownloadURL();
        } catch (error) {
          organization.orgLogoLink = null;
        }
        organizations.push(organization);
      } else if (c.type === 'modified') {
        const organizationIndex = organizations.findIndex((s) => s.id === c.doc.id);

        if (organizationIndex !== -1) {
          organizations[organizationIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
          };
        }
      } else if (c.type === 'removed') {
        const organizationIndex = organizations.findIndex((s) => s.id === c.doc.id);

        if (organizationIndex !== -1) {
          organizations.splice(organizationIndex, 1);
        }
      }
    });
    commit('SET_STATE', { key: 'organizations', value: organizations });
    commit('SET_STATE', { key: 'isLoading', value: false });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const submitOrganization = async ({ commit }, { payload }) => {
  const db = firebase.firestore();
  const { organizationLogo, ...newPayload } = payload;
  commit('SET_STATE', { key: 'isLoading', value: true });
  try {
    let orgLogoLink = '';
    if (payload.orgLogoBase64) {
      const uploadFile = firebase.functions().httpsCallable('uploadFile');
      const uploadResp = await uploadFile({
        ...payload,
        name: payload.orgLogoName,
      });
      orgLogoLink = uploadResp.data.logoLink;
    }
    if (payload.id) {
      await db
        .collection('organizations')
        .doc(payload.id)
        .update({
          ...newPayload,
          orgLogoLink,
        });
    } else {
      await db.collection('organizations').add({
        ...newPayload,
        orgLogoLink,
      });
    }
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const deleteOrganization = async ({ commit }, { payload }) => {
  const db = firebase.firestore();
  const batchUser = db.batch();
  const batchDevice = db.batch();
  commit('SET_STATE', { key: 'isLoading', value: true });
  const devRefList = [];
  try {
    const orgRef = db.collection('organizations').doc(payload.id);
    const orgSnapshot = await db.collection('users').where('organization', '==', orgRef).get();

    await db.collection('organizations').doc(payload.id).delete();
    orgSnapshot.docs.forEach((doc) => {
      batchUser.delete(doc.ref);
      devRefList.push(doc.ref);
    });
    batchUser.commit();

    const devSnapshot = await db.collection('devices').where('owner', 'in', devRefList).get();
    devSnapshot.docs.forEach((doc) => {
      batchDevice.delete(doc.ref);
    });
    batchDevice.commit();
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const getCurrentUserOrg = async ({ commit }) => {
  const db = firebase.firestore();
  const userUid = firebase.auth().currentUser.uid;
  const usersDb = db.collection('users');
  const snapshotUser = await usersDb.doc(userUid).get();
  const userData = snapshotUser.data();
  let userOrganization;
  if (userData.organization) {
    const snapshotOrganization = await userData.organization.get();
    const organizationData = snapshotOrganization.data();
    userOrganization = { ...organizationData };
  }
  commit('SET_STATE', { key: 'userOrganization', value: userOrganization });
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};
