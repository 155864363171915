import * as firebase from 'firebase/app';

const onceAuthResolved = (store, fn) => {
  if (store.state.auth.resolved) return fn();
  const unwatch = store.watch(
    (state) => state.auth.resolved,
    () => {
      unwatch();
      fn();
    },
  );
  return null;
};

export default async ({ router, store }) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('auth/request');
    return onceAuthResolved(store, async () => {  //eslint-disable-line
      const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
      const { currentUser } = firebase.auth();
      if (currentUser) {
        const { claims } = await currentUser.getIdTokenResult();
        if (claims.superAdmin || claims.eyeStaff) {
          if (['/admin/devices'].includes(to.path)) {
            return next({
              path: '/admin/organisations',
            });
          }
        }
        if (claims.orgAdmin) {
          if (['/admin/organisations'].includes(to.path)) {
            return next({
              path: '/admin/users',
            });
          }
        }
        if (!claims.superAdmin && !claims.eyeStaff && !claims.orgAdmin) {
          if (['/admin/organisations', '/admin/users'].includes(to.path)) {
            return next({
              path: '/admin/devices',
            });
          }
        }
      }
      if (requiresAuth && !currentUser) next('/login');
      else if (!requiresAuth && currentUser) next('/');
      else next();
    });
  });
};
